import { BrowserRouter } from 'react-router-dom';
import AppRouter from './router';
import { Provider } from 'react-redux';
import ModalProvider from './contexts/modalContext';
import DrawerProvider from './contexts/drawerContext';
import { store } from './redux/store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider, en_US } from 'core-ui';
import theme from './theme';

const queryClient = new QueryClient();

const App = () => {
  return (
    <ConfigProvider theme={theme} locale={en_US}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <ModalProvider>
              <DrawerProvider>
                <AppRouter />
              </DrawerProvider>
            </ModalProvider>
          </Provider>
        </QueryClientProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
