import { ApiPath } from 'src/enums/apiPath';
import { apiRequest } from 'src/utils/api';

export const getProfile = async (params: { with: string, with_count: string }) => {
  const res = await apiRequest.get({
    url: ApiPath.PROFILE,
    params
  });

  return res.data;
};