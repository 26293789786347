import { Avatar, Badge, Popover } from 'core-ui';
import Cookies from 'js-cookie';
import { RoutePath } from 'src/enums/routePath';
import { ACCESS_TOKEN_KEY } from 'src/constants/auth';
import useHandleNetwork from 'src/hooks/useHandleNetwork';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const handleLogout = () => {
    Cookies.remove(ACCESS_TOKEN_KEY);
    window.location.replace(RoutePath.LOGIN);
  };

  const { isOnline } = useHandleNetwork();
  const renderTitle = () => {
    if (currentPath?.includes(RoutePath.CREATE)) {
      return 'Create';
    }
    if (currentPath?.includes(RoutePath.DETAIL)) {
      return 'Detail';
    }
    if (currentPath?.includes(RoutePath.EDIT)) {
      return 'Edit';
    }
    if (currentPath?.includes(RoutePath.COMPANY)) {
      return 'Company management';
    }
  };
  return (
    <div className="h-[100%] flex items-center justify-between px-[20px] bg-[#ffffff]">
      <p className="text-primary text-[24px] font-[500]">{renderTitle()}</p>
      <div className="flex items-center">
        <Popover
          placement="bottomRight"
          content={
            <div className="min-w-[100px]">
              <p onClick={handleLogout} className="cursor-pointer w-full">
                ログアウト
              </p>
            </div>
          }
        >
          <div className="flex cursor-pointer items-center">
            <Avatar
              size={32}
              icon={
                <img
                  src="/avatar.png"
                  alt=""
                  style={{
                    height: '100%',
                    width: '100%'
                  }}
                />
              }
              style={{
                border: '1px solid white',
                minWidth: '32px'
              }}
              src={''}
            />

            <div className="mr-[20px] flex">
              <Badge
                color="green"
                offset={[14, 10]}
                style={{
                  display: isOnline ? 'block' : 'none',
                  height: '10px',
                  width: '10px'
                }}
              >
                <div className="flex flex-col ml-[8px]">
                  <p className="leading-[20px] font-[500] text-[#434343] text-[16px] min-w-[60px]">
                    Thomas
                  </p>
                </div>
              </Badge>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default Header;
