/* eslint-disable no-unused-vars */
export enum RoutePath {
  ALL = '/*',
  LOGIN = '/',
  NOT_FOUND = '/not-found',
  CREATE = '/create',
  DETAIL = '/detail',
  EDIT = '/edit',
  USER = '/user',
  COMPANY = '/company'
}
