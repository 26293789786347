import { ThemeConfig } from 'core-ui';

export const systemColor = {
  black: '#000000',
  white: '#FFFFFF',
  successLight: '#51FD00',
  gray: '#F2F2F2',
  green: '#006B3F',
  greenLight: '#4c8768'
};

const theme: ThemeConfig = {
  token: {
    colorPrimary: systemColor.green,
    borderRadius: 4
  },
  components: {
    Button: {
      primaryShadow: 'none'
    },
    Select: {},
    Input: {},
    Table: {},
    Menu: {
      itemMarginInline: 0,
      itemMarginBlock: 0,
      itemBorderRadius: 0,
      itemSelectedColor: systemColor.white,
      itemSelectedBg: systemColor.greenLight,
      activeBarWidth: 3,
      itemActiveBg: systemColor.greenLight,
      itemHeight: 40,
      iconSize: 18,
      itemColor: systemColor.white,
      colorItemBgSelected: systemColor.greenLight,
      itemBg: systemColor.green,
      itemHoverColor: systemColor.white
    }
  }
};
export default theme;
