import { BASE_API_URL } from 'src/constants/services';

/* eslint-disable no-unused-vars */
export const API_URL = `${BASE_API_URL}`;

export enum ApiPath {
  LOGIN = '/api/login',
  USER = '/api/v1/admin/users',
  COMPANY = '/api/companies',
  UPLOAD_IMAGE = '/api/upload-image',
  PROFILE = '/api/profile'
}
