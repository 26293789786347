import { useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { RoutePath } from 'src/enums/routePath';
import { getProfile } from 'src/services/profile';
import { Layout, MenuProps } from 'core-ui';
import Header from './components/Header';
import Logo from './components/Logo';
import { ROLE, ROLE_TEXT } from 'src/constants/auth';

const MainLayout = () => {
  const menuItems: MenuProps['items'] = [
    {
      key: 'company',
      icon: <img src="/svg/company.svg" />,
      label: <Link to={RoutePath.COMPANY}>Company management</Link>,
      className: '!w-full after:!border-e-0'
    }
  ];
  const handleGetProfile = async () => {
    try {
      const res = await getProfile({ with: '', with_count: '' });
      if (res?.data?.role) {
        localStorage.setItem(ROLE, res?.data?.role);
        localStorage.setItem(ROLE_TEXT, res?.data?.role_text);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleGetProfile();
  }, []);
  return (
    <Layout
      headerContent={<Header />}
      logoContent={<Logo />}
      logoClassName="flex justify-center items-center !bg-[#006B3F]"
      menuItems={menuItems}
      siderStyle={{
        overflowX: 'hidden'
      }}
      contentStyle={{
        overflow: 'auto',
        backgroundColor: 'unset'
      }}
    >
      <Outlet />
    </Layout>
  );
};

export default MainLayout;
